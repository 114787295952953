import React from 'react';
import { InfoCard } from '@latitude/info-card';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import { Vertical } from '@latitude/spacing';

const CorporateResponsibilityWorkplaceEquality = () => {
  return (
    <InfoCard
      bodyBGColor="#E8F4FF"
      heading="Workplace Gender Equality Agency (WGEA) Reports"
    >
      <Vertical spacing="24px">
        <Box>
          <Text align="center">
            The Workplace Gender Equality Act 2012 requires non-public sector
            employers with 100 or more employees to submit a report to the
            Workplace Gender Equality Agency. Latitude&rsquo;s report for the
            relevant reporting periods are below:&nbsp;
          </Text>

          <Link
            href="https://assets.latitudefinancial.com/corporate-responsibility/reports/workplace-gender-equality-agency/industry-benchmark-report-2023-24.pdf"
            trackId="industry-benchmark-report-2023-24"
            trackEventData={{
              label: "industry-benchmark-report-2023-24",
              location: 'corporate-responsiblity-workplace-equality',
              category: 'button-link',
              action: 'internal-link'
            }}
            width="200px"
            css={`
              margin: 50px auto 0;
            `}
          >
            Industry Benchmark Report 2023-24
          </Link> <br />
          <Link
            href="https://assets.latitudefinancial.com/corporate-responsibility/reports/workplace-gender-equality-agency/questionnaire-public-report-2023 -2024.pdf"
            trackId="corporate-responsibility-workplace-equality"
            trackEventData={{
              label: "corporate-responsibility-workplace-equality",
              location: 'corporate-responsiblity-workplace-equality',
              category: 'button-link',
              action: 'internal-link'
            }}
            width="200px"
            css={`
              margin: 50px auto 0;
            `}
          >
            Questionnaire Public Report 2023-2024
          </Link> <br />
          <Link
            href="https://assets.latitudefinancial.com/corporate-responsibility/reports/workplace-gender-equality-agency/workforce-management-statistics-report-2023-2024.pdf"
            trackId="workforce-management-statistics-report-2023-2024"
            trackEventData={{
              label: "workforce-management-statistics-report-2023-2024",
              location: 'corporate-responsiblity-workplace-equality',
              category: 'button-link',
              action: 'internal-link'
            }}
            width="200px"
            css={`
              margin: 50px auto 0;
            `}
          >
            Workforce Management Statistics Report 2023-2024
          </Link> <br />
          <Link
            href="https://assets.latitudefinancial.com/corporate-responsibility/reports/workplace-gender-equality-agency/workplace-profile-public-report-2023-2024.pdf"
            trackId="workplace-profile-public-report-2023-2024"
            trackEventData={{
              label: "workplace-profile-public-report-2023-2024",
              location: 'corporate-responsiblity-workplace-equality',
              category: 'button-link',
              action: 'internal-link'
            }}
            width="200px"
            css={`
              margin: 50px auto 0;
            `}
          >
            Workplace Profile Public Report 2023-2024
          </Link> <br />
        </Box>
      </Vertical>
    </InfoCard>
  );
};

export default CorporateResponsibilityWorkplaceEquality;
